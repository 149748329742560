import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useParams } from "react-router-dom"
import { getMe, getSamplerequest } from "../../api";
import { ISamplerequestDetail, IUser } from "../../types";
import { Box, Button, Card, CardBody, CardHeader, Container, Flex, HStack, Heading, Stack, StackDivider } from "@chakra-ui/react";
import { Document, Page, Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink, Font } from '@react-pdf/renderer';
import Footer from "../../components/PDF/Samplerequest/Footer";
import Header from "../../components/PDF/Samplerequest/Header";
import Header2 from "../../components/PDF/Samplerequest/Header2";
import Header3 from "../../components/PDF/Samplerequest/Header3";
import Middle1 from "../../components/PDF/Samplerequest/Middle1";
import Middle2 from "../../components/PDF/Samplerequest/Middle2";
import Middle3 from "../../components/PDF/Samplerequest/Middle3";
import { VscFilePdf } from "react-icons/vsc";
import { useMemo } from "react";
import SamplerequestDetailSkeleton from "../../components/Samplerequest/SamplerequestDetailSkeleton";
import { addWeeks, addMonths, format } from 'date-fns';


Font.register({
    family: 'Noto Sans',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2',
      },
      {
        src: 'https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2',
        fontWeight: 'bold',
      },
    ],
  });


const styles = StyleSheet.create({
    body: {
    backgroundColor: '#ffffff',
    fontFamiy: 'Noto Sans',
    fontSize: 10,
    lineHeight: 1.4,
    padding: 16,
    paddingHorizontal: 16,
    height: '100vh',
    display: 'flex',
    size: "A4",
    },
    top: {
        flex: 1.8,
        
    },
    middle1: {
        flex: 2.5,
    },
    middle2: {
        flex: 5,
        

    },
    middle3t: {
        flex: 2.4,
    },
    middle3: {
        flex: 5,
        border: "2px solid #000",
        marginLeft: "40px", marginRight: "40px", marginTop: "20px",marginBottom: "40px",
    },
    bottom: {
        justifyContent: "flex-end",
        flex: 2.1,
        fontSize: 8,
    },
    body_m: {
        paddingLeft: "20px", paddingRight: "20px", paddingTop: "20px",paddingBottom: "40px"
    },
    signature: {
        display: 'flex',
        paddingLeft: "20px",
        paddingRight: "20px",
        },
    signaguresub: {
        flexDirection: 'row',
        justifyContent: "space-between",
    },
})

export default function SamplerequestDetail() {

    
function calculateReturnDates(sr_submission_date?: string): { twoWeeksLater: string, oneMonthLater: string } | null {
    // sr_submission_date가 undefined나 null이면 null 반환
    if (!sr_submission_date) {
      console.error("sr_submission_date is undefined or null");
      return null;
    }
  
    try {
      // Convert "DD/MMM/YYYY" to "DD MMM YYYY" to parse with date-fns
      const parsedDate = new Date(sr_submission_date.replace(/\/(\w{3})\//, ' $1 '));
  
      // Add 2 weeks
      const twoWeeksLater = addWeeks(parsedDate, 2);
      const formattedTwoWeeksLater = format(twoWeeksLater, 'dd/MM/yyyy');
  
      // Add 1 month
      const oneMonthLater = addMonths(parsedDate, 1);
      const formattedOneMonthLater = format(oneMonthLater, 'dd/MM/yyyy');
  
      return {
        twoWeeksLater: formattedTwoWeeksLater,
        oneMonthLater: formattedOneMonthLater,
      };
    } catch (error) {
      console.error("Error parsing date:", error);
      return null;
    }
  }

    const { samplerequestPk } = useParams();
    const navigate = useNavigate();

    const { isLoading, data } = useQuery<ISamplerequestDetail>([`samplerequests`, samplerequestPk], getSamplerequest);
    const { data: userData } = useQuery<IUser>([`users`], getMe);
    const minimax_number = userData?.minimax_number;
    const kind = data?.kind!
    const demo_kind = data?.demo_kind!
    const demo_tested = data?.demo_tested!
    const srdrname = data?.srdrname!
    const sr_id = data?.sr_id!
    const submitter = data?.submitter.name!
    const sr_submission_date = data?.sr_submission_date!
    const srclinicname = data?.srclinicname!
    const srcliniccode = data?.srcliniccode!
    const srbillingemail = data?.srbillingemail!
    const SAMPLE = "Sample"
    const DEMO = "Demo"

    const result = calculateReturnDates(sr_submission_date);


const MyPDF = () => (
    <Document>
        <Page style={styles.body} >
            <View style={styles.top}>
                <Header sr_id={sr_id}>Request Form</Header>
            </View>
            <View style={styles.middle1}>
                <Middle1 kind={kind} sr_id={sr_id} submitter={submitter} srdrname={srdrname} sr_submission_date={sr_submission_date} srclinicname={srclinicname} srcliniccode={srcliniccode} demo_kind={demo_kind} srbillingemail={srbillingemail}></Middle1>
            </View>
            

            <View style={styles.middle2}>
                <View style={{display: 'flex'}}>
                    <View style={{flexDirection: "row", textAlign: "center"}}>
                        <Text style={{flex: 1, backgroundColor: "#f0f0f0", color: "#000", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px", borderTop: "1px solid #D1D3D4",  borderBottom: "1px solid #D1D3D4", borderLeft: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4",}}>ID</Text>
                        <Text style={{flex: 6, backgroundColor: "#f0f0f0", color: "#000", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px", borderTop: "1px solid #D1D3D4", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}>Product</Text>
                        <Text style={{flex: 1, backgroundColor: "#f0f0f0", color: "#000", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px", borderTop: "1px solid #D1D3D4", borderBottom: "1px solid #D1D3D4",  borderRight: "1px solid #D1D3D4"}}>Qty</Text>
                        <Text style={{flex: 2, backgroundColor: "#f0f0f0", color: "#000", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px", borderTop: "1px solid #D1D3D4", borderBottom: "1px solid #D1D3D4",  borderRight: "1px solid #D1D3D4"}}>Unit Price</Text>
                        <Text style={{flex: 2, backgroundColor: "#f0f0f0", color: "#000", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px", borderTop: "1px solid #D1D3D4", borderBottom: "1px solid #D1D3D4",  borderRight: "1px solid #D1D3D4"}}>Amount (inc. gst)</Text>
                    </View>
                </View>
                {data?.samplerequest_items.map((key, index) => {
                    return <View key={index} style={{display: 'flex'}}>
                        <View style={{flexDirection: "row", textAlign: "center"}}>
                            {(key.unit_price > 0) ? 
                            <>
                                <Text style={{flex: 1, paddingTop: "5px", color: "#000", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderLeft: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{index+1}</Text>
                                <Text style={{fontFamily: "Noto Sans", flex: 6, color: "#000", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.item}</Text>
                                <Text style={{fontFamily: "Noto Sans", flex: 1, color: "#000", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4",  borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.qty}</Text>
                                <Text style={{fontFamily: "Noto Sans", flex: 2, paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{(Math.round(key?.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                <Text style={{fontFamily: "Noto Sans", flex: 2, paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", fontSize: "8px", borderRight: "1px solid #D1D3D4"}}>{(Math.round(key?.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                            </> 
                            :
                            <>
                            <Text style={{fontFamily: "Noto Sans", flex: 1, paddingTop: "5px", color: "#000", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderLeft: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{index+1}</Text>
                            <Text style={{fontFamily: "Noto Sans", flex: 6, color: "#000", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.item}</Text>
                            <Text style={{fontFamily: "Noto Sans", flex: 1, color: "#000", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4",  borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.qty}</Text>
                            <Text style={{fontFamily: "Noto Sans", flex: 2, paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{(Math.round(key?.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                            <Text style={{fontFamily: "Noto Sans", flex: 2, color: "red", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", fontSize: "8px"}}>{(Math.round(key?.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                            </>
                            } 
                        </View>
                    </View>
                    ;
                })}
                <View style={{display: "flex", paddingRight: 20,  paddingLeft: 20}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{flex: 7, display: "flex"}}>
                            <View style={{padding: "10px", marginTop: "17px", flexDirection: 'row'}}>
                                <Text style={{flex: 1, fontSize: "12px", marginBottom: "5px"}}>Reason: </Text>
                                <Text style={{flex: 5, color: "#000", fontSize: "10px", fontFamily: "Noto Sans"}}>{data?.description}</Text>
                            </View>
                        </View>

                        <View style={{flex: 3, marginTop: "10px", padding: "10px",}}>
                            <View style={{display: "flex"}}>
                                <View style={{flexDirection: "row", justifyContent: "flex-end"}}>
                                    <View style={{}}>
                                        <Text style={{backgroundColor: "#fff", color: "#292929", fontSize: "10px", marginBottom: "3px",paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>Total:</Text>
                                        <Text style={{backgroundColor: "#fff", color: "#292929", fontSize: "10px", marginBottom: "3px", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>GST:</Text>
                                        <Text style={{backgroundColor: "#fff", color: "#292929", fontSize: "10px",  paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>Final Price:</Text>
                                    </View>
                                    <View style={{textAlign: "center", alignItems: "flex-end"}}>
                                        {(data?.sr_total_price == 0) ?
                                        <>
                                        <Text style={{fontSize: "10px",  marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>$ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Text>
                                        <Text style={{fontSize: "10px",marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>$ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Text>
                                        <Text style={{fontSize: "10px", marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>$ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Text>
                                        </>
                                        :
                                        <>
                                        <Text style={{fontSize: "10px",  marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>$ {Math.round(data?.sr_total_price! *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                        <Text style={{fontSize: "10px",marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>$ {Math.round(data?.sr_gst_price! *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                        <Text style={{fontSize: "10px", marginBottom: "3px", textAlign: "center", paddingTop: "6px", paddingBottom: "1px", paddingRight: "7px", paddingLeft: "10px", borderBottom: "0.2px solid #292929"}}>$ {Math.round(data?.sr_final_price! *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                        </>
                                        }
                                    </View>
                                </View>
                            </View>
                        </View>
                        
                    </View>
                </View>
            </View>
            <View style={styles.body_m}>
                <View style={styles.signature}>
                    <View style={styles.signaguresub}>
                        <View style={{flexDirection: "row" }}>
                            <View style={{flexDirection: "column", width: "32%"}}>
                                <View style={{backgroundColor: "#f0f0f0", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                    <Text style={{textAlign: "center", color: "#292929"}}>SALES REPRESENTATIVE</Text>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                        <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                            <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>{submitter}</Text>
                                            <Text style={{ color: "#292929", fontSize: "8px", marginTop: "8px"}}>{sr_submission_date}</Text>
                                        </View>
                                    </View>
                                    <View>
                                        <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                    </View>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                    <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D",  fontSize: "7px", marginTop: "10px"}}></Text>
                                    </View>
                                    <View>
                                        <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{backgroundColor: "#fff", height: "20px", width: "2%", color: "#fff"}}>
                            </View>
                            <View style={{flexDirection: "column", width: "32%"}}>
                                <View style={{backgroundColor: "#6e6e6e", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                    <Text style={{textAlign: "center"}}>SALES TEAM</Text>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                        <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                            <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>Charlie Hong</Text>
                                            <Text style={{ color: "#94928D", fontSize: "8px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                            
                                        </View>
                                    </View>
                                    <View>
                                        <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                    </View>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                        <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                    </View>
                                    <View>
                                        <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{backgroundColor: "#fff", height: "20px", width: "2%", color: "#fff"}}>
                            </View>
                            <View style={{flexDirection: "column", width: "32%"}}>
                                <View style={{backgroundColor: "#292929", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                    <Text style={{textAlign: "center"}}>DIRECTOR</Text>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                        <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                            <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>Jun Park</Text>
                                            <Text style={{ color: "#94928D", fontSize: "8px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                        </View>
                                    </View>
                                    <View>
                                        <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                    </View>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                        <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                    </View>
                                    <View>
                                        <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        
                    </View>
                </View>
                
            </View>
            <View style={styles.bottom}>
               
                {/* <ProposalSignature/> */}
                <Footer/>
            </View>
            {/* <Text render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}></Text> */}
        </Page>
    </Document>


    )

const MyDemoPDF = () => (
    <Document>
        <Page style={styles.body} >
            <View style={styles.top}>
                <Header2 sr_id={sr_id}>Request Form</Header2>
            </View>
            <View style={styles.middle1}>
                <Middle2 kind={kind} sr_id={sr_id} submitter={submitter} srdrname={srdrname} sr_submission_date={sr_submission_date} srclinicname={srclinicname} srcliniccode={srcliniccode} demo_kind={demo_kind} srbillingemail={srbillingemail} demo_tested={demo_tested}></Middle2>
            </View>
            

            <View style={styles.middle2}>
                <View style={{display: 'flex'}}>
                    <View style={{flexDirection: "row", textAlign: "center"}}>
                        <Text style={{flex: 1, backgroundColor: "#f0f0f0", color: "#000", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px", borderTop: "1px solid #D1D3D4",  borderBottom: "1px solid #D1D3D4", borderLeft: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4",}}>ID</Text>
                        <Text style={{flex: 6, backgroundColor: "#f0f0f0", color: "#000", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px", borderTop: "1px solid #D1D3D4", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4"}}>Product</Text>
                        <Text style={{flex: 2, backgroundColor: "#f0f0f0", color: "#000", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px", borderTop: "1px solid #D1D3D4", borderBottom: "1px solid #D1D3D4",  borderRight: "1px solid #D1D3D4"}}>Label</Text>
                        <Text style={{flex: 1, backgroundColor: "#f0f0f0", color: "#000", paddingTop: "4px", paddingBottom: "3px", fontSize: "9px", borderTop: "1px solid #D1D3D4", borderBottom: "1px solid #D1D3D4",  borderRight: "1px solid #D1D3D4"}}>Qty</Text>
                    </View>
                </View>
                {data?.samplerequest_items.map((key, index) => {
                    return <View key={index} style={{display: 'flex'}}>
                        <View style={{flexDirection: "row", textAlign: "center"}}>
                            {(key.unit_price > 0) ? 
                            <>
                                <Text style={{fontFamily: "Noto Sans",flex: 1, paddingTop: "5px", color: "#000", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderLeft: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{index+1}</Text>
                                <Text style={{fontFamily: "Noto Sans",flex: 6, color: "#000", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.item}</Text>
                                <Text style={{fontFamily: "Noto Sans",flex: 2, color: "#000", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4",  borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.serial_number}</Text>
                                <Text style={{fontFamily: "Noto Sans",flex: 1, color: "#000", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4",  borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.qty}</Text>
                            </> 
                            :
                            <>
                            <Text style={{flex: 1, paddingTop: "5px", color: "#000", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderLeft: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{index+1}</Text>
                            <Text style={{flex: 6, color: "#000", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4", borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.item}</Text>
                            <Text style={{flex: 2, color: "#000", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4",  borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.serial_number}</Text>
                            <Text style={{flex: 1, color: "#000", paddingTop: "5px", paddingBottom: "3px", borderBottom: "1px solid #D1D3D4",  borderRight: "1px solid #D1D3D4", fontSize: "8px"}}>{key.qty}</Text>
                            </>
                            } 
                        </View>
                    </View>
                    ;
                })}
                <View style={{display: "flex", paddingRight: 20,  paddingLeft: 20}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{flex: 7, display: "flex"}}>
                            <View style={{padding: "10px", marginTop: "17px", flexDirection: 'row'}}>
                                <Text style={{flex: 1, fontSize: "12px", marginBottom: "5px"}}>Reason: </Text>
                                <Text style={{flex: 5, color: "#000", fontSize: "10px", fontFamily: "Noto Sans"}}>{data?.description}</Text>
                            </View>
                        </View>

                        
                        
                    </View>
                </View>
            </View>
     
            <View style={styles.body_m}>
                <View style={styles.signature}>
                {demo_kind == "TRIAL" && (
                    <>
                    <View><Text>Expected return date {result?.twoWeeksLater}</Text></View>
                    <View style={{marginBottom: "15px"}}><Text>$50 + GST will be charged if not returned on the agreed date</Text></View>
                    </>
                )}
                {demo_kind == "REPAIR" && (
                    <>
                    <View><Text>Expected return date {result?.oneMonthLater}</Text></View>
                    <View style={{marginBottom: "15px"}}><Text>$50 + GST will be charged if not returned on the agreed date</Text></View>
                    </>
                )}
                    <View style={styles.signaguresub}>

                        <View style={{flexDirection: "row" }}>
                            <View style={{flexDirection: "column", width: "32%"}}>
                                <View style={{backgroundColor: "#f0f0f0", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                    <Text style={{textAlign: "center", color: "#292929"}}>DENTIST</Text>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                        <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                            <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}></Text>
                                            <Text style={{ color: "#f9f9f9", fontSize: "8px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>

                                        </View>
                                    </View>
                                    <View>
                                        <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                    </View>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                    <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D",  fontSize: "7px", marginTop: "10px"}}></Text>
                                    </View>
                                    <View>
                                        <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{backgroundColor: "#fff", height: "20px", width: "2%", color: "#fff"}}>
                            </View>
                            <View style={{flexDirection: "column", width: "32%"}}>
                                <View style={{backgroundColor: "#6e6e6e", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                    <Text style={{textAlign: "center"}}>SALES TEAM</Text>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                        <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                            <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>{submitter}</Text>
                                            <Text style={{ color: "#292929", fontSize: "8px", marginTop: "8px"}}>{sr_submission_date}</Text>
                                            
                                        </View>
                                    </View>
                                    <View>
                                        <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                    </View>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                        <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                    </View>
                                    <View>
                                        <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{backgroundColor: "#fff", height: "20px", width: "2%", color: "#fff"}}>
                            </View>
                            <View style={{flexDirection: "column", width: "32%"}}>
                                <View style={{backgroundColor: "#292929", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                    <Text style={{textAlign: "center"}}>RESPONSIBILITY</Text>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                        <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                            <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>Prisca Yong | Skylar Jang</Text>
                                        </View>
                                    </View>
                                    <View>
                                        <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                    </View>
                                </View>
                                <View style={{marginTop: "7px"}}>
                                    <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                        <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                    </View>
                                    <View>
                                        <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        
                    </View>
                </View>
                
            </View>
            <View style={styles.bottom}>
                {/* <ProposalSignature/> */}
                <Footer/>
            </View>
            {/* <Text render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}></Text> */}
        </Page>
    </Document>
    )

const MyProposalPDF = () => (
        <Document>
            <Page style={styles.body} >
                <View style={styles.top}>
                    <Header3 sr_id={sr_id}>Request Form</Header3>
                </View>
                <View style={styles.middle3t}>
                    <Middle3 kind={kind} sr_id={sr_id} submitter={submitter} srdrname={srdrname} sr_submission_date={sr_submission_date} srclinicname={srclinicname} srcliniccode={srcliniccode} demo_kind={demo_kind} srbillingemail={srbillingemail} ></Middle3>
                </View>
                
    
                <View style={styles.middle3}>
                    <View style={{textAlign: "center", fontFamily: "Noto Sans", marginTop: "10px"}}>
                        <Text style={{ fontSize: "8px"}}>아래와 같이 발행된 기안서에 대한 승인을 요청하오니, 재가 바랍니다.</Text>
                    </View>
                    <View style={{display: "flex"}}>
                        <View style={{textAlign: "center", fontFamily: "Noto Sans", flexDirection: 'row', justifyContent: "center", marginTop: "10px",  fontSize: "8px"}}>
                            <Text style={{}}>-</Text><Text style={{marginLeft: "20px", marginRight: "15px"}}>아</Text><Text style={{marginLeft: "15px", marginRight: "20px"}}>래</Text><Text style={{}}>-</Text>
                        </View>
                    </View>
                    <View style={{marginTop: "10px"}}>
                        <Text style={{textAlign: "center", fontFamily: "Noto Sans", fontSize: "8px"}}>{data?.description}</Text>
                    </View>
                </View>
         
                <View style={styles.body_m}>
                    <View style={styles.signature}>
                    
                        <View style={styles.signaguresub}>
    
                            <View style={{flexDirection: "row" }}>
                                <View style={{flexDirection: "column", width: "32%"}}>
                                    <View style={{backgroundColor: "#f0f0f0", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center", color: "#292929"}}>Submitter</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>{submitter}</Text>
                                                <Text style={{ color: "#292929", fontSize: "8px", marginTop: "8px"}}>{sr_submission_date}</Text>
    
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                        <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D",  fontSize: "7px", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{backgroundColor: "#fff", height: "20px", width: "2%", color: "#fff"}}>
                                </View>
                                <View style={{flexDirection: "column", width: "32%"}}>
                                    <View style={{backgroundColor: "#6e6e6e", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>TEAM MANAGER</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>Charlie Hong</Text>
                                                <Text style={{ color: "rgb(242,246,252)", fontSize: "8px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                                
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{backgroundColor: "#fff", height: "20px", width: "2%", color: "#fff"}}>
                                </View>
                                <View style={{flexDirection: "column", width: "32%"}}>
                                    <View style={{backgroundColor: "#292929", height: "20px", color: "#fff", fontSize: "8px", paddingTop: "5px"}}>
                                        <Text style={{textAlign: "center"}}>DIRECTOR</Text>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <View style={{flexDirection: "row", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "10px"}}>
                                                <Text style={{ color: "#292929", fontSize: "10px", marginTop: "6px"}}>Jun Park</Text>
                                                <Text style={{ color: "rgb(242,246,252)", fontSize: "8px", marginTop: "8px"}}>(DD/MM/YYYY)</Text>
                                            </View>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Print Name & Date</Text>
                                        </View>
                                    </View>
                                    <View style={{marginTop: "7px"}}>
                                        <View style={{height: "30px", borderBottom: "1px solid #292929"}}>
                                            <Text style={{textAlign: "right", paddingRight: "3px", paddingBottom: "2px", color: "#94928D", fontSize: "7px", justifyContent:"flex-end", marginTop: "10px"}}></Text>
                                        </View>
                                        <View>
                                            <Text style={{textAlign: "center", color: "#292929", fontSize: "7px", marginTop: "2px"}}>Authorized Signature</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            
                        </View>
                    </View>
                    
                </View>
                <View style={styles.bottom}>
                    {/* <ProposalSignature/> */}
                    <Footer/>
                </View>
                {/* <Text render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}></Text> */}
            </Page>
        </Document>
    
    
        )
    const PDFMemo = useMemo(
        () => (
            <PDFDownloadLink document={<MyPDF />} fileName={`${data?.submitter.name}_${data?.sr_id}`}>
            {({loading}) => 
                loading? (
                <>Loading Document...</>
                ) : (
                    <VscFilePdf />
                )
            }
            </PDFDownloadLink>
        ), [])

    function PDF() {
        return PDFMemo;
    }
    function CheckUser() {
        if(userData?.role === "Boss" && data?.sr_id || userData?.name ==="Charlie Hong"  && data?.sr_id ||  userData?.name ==="Sean Hyun" && data?.sr_id || userData?.name ==="Skylar Jang" && data?.sr_id || userData?.name ==="Prisca Yong" && data?.sr_id || userData?.name ==="Ellena Sim" && data?.sr_id || userData?.name ==="Steve Kim" && data?.sr_id) {
            return <>
                  
            {kind == "Sample" && (
            <Box 
                pb={20}
                pt={20}
                px={{  
                    base: 10,
                    md: 20,
                    lg: "80px",
                }}
                backgroundColor="#f9f9f9"
                borderRadius="20px"
                >
                <Box display="flex" justifyContent="space-between" mb="20">
                    <HStack><Box><Link to={`/samplerequests/`}><Button colorScheme='facebook'>&#x2190; 샘플 / 데모 / 기안 리스트</Button></Link></Box></HStack>

                    <Box><PDFDownloadLink document={<MyPDF />} fileName={`${data?.submitter.name}_${data?.sr_id}`}>
                    {({loading}) => 
                        loading? (
                        <>Loading Document...</>
                        ) : (
                            <Button colorScheme='facebook'><VscFilePdf /></Button>
                        )
                    }
                    </PDFDownloadLink>
                    </Box>
                </Box>
                
                <Box paddingRight="10" paddingLeft="10">

                <Box textAlign="center" mb="60px">
                    <Stack><Box fontSize="24">[{data?.kind} Request]</Box></Stack>
                </Box>

                    
                    <HStack mb="7"><Box fontSize="20" fontWeight="bold">{data?.srclinicname}</Box><Box>&</Box><Box fontSize="20" fontWeight="bold">{data?.srcliniccode}</Box></HStack>

                    <Box display="flex" justifyContent="space-between" mt={2} fontSize="15" >
                        <Flex>
                            <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Flex mr={5}>
                                        <Box>
                                            <Box>Dr's Nmae:</Box>
                                            <Box>Clinic:</Box>
                                            <Box>Ecount Code:</Box>
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <Box>{data?.srdrname}</Box>
                                            <Box>{data?.srclinicname}</Box>
                                            <Box>{data?.srcliniccode}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                        <Flex>
                            <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Flex mr={5}>
                                        <Box>
                                            <Box>Request Form ID:</Box>
                                            <Box>Sales Rep:</Box>
                                            <Box>Date:</Box>
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <Box>{data?.sr_id}</Box>
                                            <Box>{data?.submitter.name}</Box>
                                            <Box>{data?.sr_submission_date}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
                
                
                {/* Quotation Itmes */}
                <Box display="flex" flexDirection="column" textAlign="center" fontSize="15" mt={20}>
                <Flex mt={5} bg="#385898" color="white" pt="7px" pb="7px">
                    <Box flex="1">ID</Box>
                    <Box flex="6">Description</Box>
                    <Box flex="1">QTY</Box>
                    <Box flex="2">UNIT PRICE</Box>
                    <Box flex="2">AMOUNT</Box>
                </Flex>
                
                {data?.samplerequest_items.map((key, index) => <Flex key={index}>
                {(key.unit_price > 0) ?  
                <>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.item}</Box>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                    <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                    <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4">{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                </> 
                :
                <>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.item}</Box>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                    <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                    <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4">{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                </>  
                }   
                </Flex> 
                )}
                </Box>
                <Box display="flex" justifyContent="space-between" mt={10} fontSize="15" paddingRight="10" paddingLeft="10">
                    <Flex flex="7">
                        <Box>
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <Box color="red" fontSize="10px"><pre><Box>{data?.description}</Box></pre></Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                    <Flex flex={3}></Flex>
                    <Flex flex="0 0 250px" justifyContent="flex-end">
                        <Box fontSize="18px" background="#292929" color="#fff" padding="12px 0 6px 15px" borderRadius="20px">
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box fontWeight="700">Total Price: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">GST: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">Final Price: </Box></HStack>
                                    </Box>
                                </Flex>
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_total_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_gst_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_final_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                {/* Special Condition */}

                {/* Terms & Condition */}
                {/* 
                {data?.quotetncs.map((key, index) => <Box key={index}>
                    <pre><Box mt={20}>{ key.description }</Box></pre>
                </Box> )}
                */}
                </Box>
            )}

            {kind == "Demo" && (
            <Box 
                pb={20}
                pt={20}
                px={{  
                    base: 10,
                    md: 20,
                    lg: "80px",
                }}
                backgroundColor="#f9f9f9"
                borderRadius="20px"
                >
                <Box display="flex" justifyContent="space-between" mb="20">
                    <HStack><Box><Link to={`/samplerequests/`}><Button colorScheme='facebook'>&#x2190; 샘플 / 데모 / 기안 리스트</Button></Link></Box></HStack>

                    <Box><PDFDownloadLink document={<MyDemoPDF />} fileName={`${data?.submitter.name}_${data?.sr_id}`}>
                    {({loading}) => 
                        loading? (
                        <>Loading Document...</>
                        ) : (
                            <Button colorScheme='facebook'><VscFilePdf /></Button>
                        )
                    }
                    </PDFDownloadLink>
                    </Box>
                </Box>
                
                <Box paddingRight="10" paddingLeft="10">

                <Box textAlign="center" mb="60px">
                    <Stack><Box fontSize="24">[{data?.kind} Request]</Box></Stack>
                </Box>

                    
                    <HStack mb="7"><Box fontSize="20" fontWeight="bold">{data?.srclinicname}</Box><Box>&</Box><Box fontSize="20" fontWeight="bold">{data?.srcliniccode}</Box></HStack>

                    <Box display="flex" justifyContent="space-between" mt={2} fontSize="15" >
                        <Flex>
                            <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Flex mr={5}>
                                        <Box>
                                            <Box>Dr's Nmae:</Box>
                                            <Box>Clinic:</Box>
                                            <Box>Ecount Code:</Box>
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <Box>{data?.srdrname}</Box>
                                            <Box>{data?.srclinicname}</Box>
                                            <Box>{data?.srcliniccode}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                        <Flex>
                            <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Flex mr={5}>
                                        <Box>
                                            <Box>Request Form ID:</Box>
                                            <Box>Sales Rep:</Box>
                                            <Box>Date:</Box>
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <Box>{data?.sr_id}</Box>
                                            <Box>{data?.submitter.name}</Box>
                                            <Box>{data?.sr_submission_date}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
                
                
                {/* Quotation Itmes */}
                <Box display="flex" flexDirection="column" textAlign="center" fontSize="15" mt={20}>
                <Flex mt={5} bg="#385898" color="white" pt="7px" pb="7px">
                    <Box flex="1">ID</Box>
                    <Box flex="6">Item</Box>
                    <Box flex="2">Label</Box>
                    <Box flex="1">QTY</Box>
                </Flex>
                
                {data?.samplerequest_items.map((key, index) => <Flex key={index}>
                {(key.unit_price > 0) ?  
                <>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.item}</Box>
                    <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.serial_number}</Box>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                </> 
                :
                <>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.item}</Box>
                    <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.serial_number}</Box>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                </>  
                }   
                </Flex> 
                )}
                </Box>
                <Box display="flex" justifyContent="space-between" mt={10} fontSize="15" paddingRight="10" paddingLeft="10">
                    <Flex flex="7">
                        <Box>
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <Box color="red" fontSize="10px"><pre><Box>{data?.description}</Box></pre></Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                    <Flex flex={3}></Flex>
                    <Flex flex="0 0 250px" justifyContent="flex-end">
                        <Box fontSize="18px" background="#292929" color="#fff" padding="12px 0 6px 15px" borderRadius="20px">
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box fontWeight="700">Total Price: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">GST: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">Final Price: </Box></HStack>
                                    </Box>
                                </Flex>
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_total_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_gst_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_final_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                {/* Special Condition */}

                {/* Terms & Condition */}
                {/* 
                {data?.quotetncs.map((key, index) => <Box key={index}>
                    <pre><Box mt={20}>{ key.description }</Box></pre>
                </Box> )}
                */}
                </Box>
            )}

            {kind == "Draft Proposal" && (
            <Box 
                pb={20}
                pt={20}
                px={{  
                    base: 10,
                    md: 20,
                    lg: "80px",
                }}
                backgroundColor="#f9f9f9"
                borderRadius="20px"
                >
                <Box display="flex" justifyContent="space-between" mb="20">
                    <HStack><Box><Link to={`/samplerequests/`}><Button colorScheme='facebook'>&#x2190; 샘플 / 데모 / 기안 리스트</Button></Link></Box></HStack>

                    <Box><PDFDownloadLink document={<MyProposalPDF />} fileName={`${data?.submitter.name}_${data?.sr_id}`}>
                    {({loading}) => 
                        loading? (
                        <>Loading Document...</>
                        ) : (
                            <Button colorScheme='facebook'><VscFilePdf /></Button>
                        )
                    }
                    </PDFDownloadLink>
                    </Box>
                </Box>
                
                <Box paddingRight="10" paddingLeft="10">

                <Card>
                    <CardHeader>
                        <Heading size='md'>{data?.kind} Request</Heading>
                    </CardHeader>

                    <CardBody>
                        <Stack divider={<StackDivider />} spacing='4'>
                        <Box>
                            <Heading size='xs' textTransform='uppercase'>
                            </Heading>
                            <Box style={{display: "flex"}}>
                                <Flex mr={5}>
                                    <Box>
                                        <Box>Request Form ID:</Box>
                                        <Box>Sales Rep:</Box>
                                        <Box>Date:</Box>
                                    </Box>
                                </Flex>
                                <Flex>
                                    <Box>
                                        <Box>{data?.sr_id}</Box>
                                        <Box>{data?.submitter.name}</Box>
                                        <Box>{data?.sr_submission_date}</Box>
                                    </Box>
                                </Flex>                            
                            </Box>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase'>
                            Reason
                            </Heading>
                            <Text>
                            {data?.description}
                            </Text>
                        </Box>
                        
                        </Stack>
                    </CardBody>
                </Card>
                    
                </Box>
                
                <Box>

                </Box>
                {/* Special Condition */}

                {/* Terms & Condition */}
                {/* 
                {data?.quotetncs.map((key, index) => <Box key={index}>
                    <pre><Box mt={20}>{ key.description }</Box></pre>
                </Box> )}
                */}
                </Box>
            )}
            </>
            
        } else if (userData?.role === "Sales Team" && data?.sr_id) {
            if(minimax_number === data?.submitter.minimax_number) {
                return <>
                  
            {kind == "Sample" && (
            <Box 
                pb={20}
                pt={20}
                px={{  
                    base: 10,
                    md: 20,
                    lg: "80px",
                }}
                backgroundColor="#f9f9f9"
                borderRadius="20px"
                >
                <Box display="flex" justifyContent="space-between" mb="20">
                    <HStack><Box><Link to={`/samplerequests/`}><Button colorScheme='facebook'>&#x2190; 샘플 / 데모 / 기안 리스트</Button></Link></Box></HStack>

                    <Box><PDFDownloadLink document={<MyPDF />} fileName={`${data?.submitter.name}_${data?.sr_id}`}>
                    {({loading}) => 
                        loading? (
                        <>Loading Document...</>
                        ) : (
                            <Button colorScheme='facebook'><VscFilePdf /></Button>
                        )
                    }
                    </PDFDownloadLink>
                    </Box>
                </Box>
                
                <Box paddingRight="10" paddingLeft="10">

                <Box textAlign="center" mb="60px">
                    <Stack><Box fontSize="24">[{data?.kind} Request]</Box></Stack>
                </Box>


                    
                    <HStack mb="7"><Box fontSize="20" fontWeight="bold">{data?.srclinicname}</Box><Box>&</Box><Box fontSize="20" fontWeight="bold">{data?.srcliniccode}</Box></HStack>

                    <Box display="flex" justifyContent="space-between" mt={2} fontSize="15" >
                        <Flex>
                            <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Flex mr={5}>
                                        <Box>
                                            <Box>Dr's Nmae:</Box>
                                            <Box>Clinic:</Box>
                                            <Box>Ecount Code:</Box>
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <Box>{data?.srdrname}</Box>
                                            <Box>{data?.srclinicname}</Box>
                                            <Box>{data?.srcliniccode}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                        <Flex>
                            <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Flex mr={5}>
                                        <Box>
                                            <Box>Request Form ID:</Box>
                                            <Box>Sales Rep:</Box>
                                            <Box>Date:</Box>
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <Box>{data?.sr_id}</Box>
                                            <Box>{data?.submitter.name}</Box>
                                            <Box>{data?.sr_submission_date}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
                
                
                {/* Quotation Itmes */}
                <Box display="flex" flexDirection="column" textAlign="center" fontSize="15" mt={20}>
                <Flex mt={5} bg="#385898" color="white" pt="7px" pb="7px">
                    <Box flex="1">ID</Box>
                    <Box flex="6">Description</Box>
                    <Box flex="1">QTY</Box>
                    <Box flex="2">UNIT PRICE</Box>
                    <Box flex="2">AMOUNT</Box>
                </Flex>
                
                {data?.samplerequest_items.map((key, index) => <Flex key={index}>
                {(key.unit_price > 0) ?  
                <>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.item}</Box>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                    <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                    <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4">{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                </> 
                :
                <>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.item}</Box>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                    <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{(Math.round(key.unit_price*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                    <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4">{(Math.round(key.amount*100 /100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Box>
                </>  
                }   
                </Flex> 
                )}
                </Box>
                <Box display="flex" justifyContent="space-between" mt={10} fontSize="15" paddingRight="10" paddingLeft="10">
                    <Flex flex="7">
                        <Box>
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <Box color="red" fontSize="10px"><pre><Box>{data?.description}</Box></pre></Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                    <Flex flex={3}></Flex>
                    <Flex flex="0 0 250px" justifyContent="flex-end">
                        <Box fontSize="18px" background="#292929" color="#fff" padding="12px 0 6px 15px" borderRadius="20px">
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box fontWeight="700">Total Price: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">GST: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">Final Price: </Box></HStack>
                                    </Box>
                                </Flex>
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_total_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_gst_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_final_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                {/* Special Condition */}

                {/* Terms & Condition */}
                {/* 
                {data?.quotetncs.map((key, index) => <Box key={index}>
                    <pre><Box mt={20}>{ key.description }</Box></pre>
                </Box> )}
                */}
                </Box>
            )}

            {kind == "Demo" && (
            <Box 
                pb={20}
                pt={20}
                px={{  
                    base: 10,
                    md: 20,
                    lg: "80px",
                }}
                backgroundColor="#f9f9f9"
                borderRadius="20px"
                >
                <Box display="flex" justifyContent="space-between" mb="20">
                    <HStack><Box><Link to={`/samplerequests/`}><Button colorScheme='facebook'>&#x2190; 샘플 / 데모 / 기안 리스트</Button></Link></Box></HStack>

                    <Box><PDFDownloadLink document={<MyDemoPDF />} fileName={`${data?.submitter.name}_${data?.sr_id}`}>
                    {({loading}) => 
                        loading? (
                        <>Loading Document...</>
                        ) : (
                            <Button colorScheme='facebook'><VscFilePdf /></Button>
                        )
                    }
                    </PDFDownloadLink>
                    </Box>
                </Box>
                
                <Box paddingRight="10" paddingLeft="10">

                <Box textAlign="center" mb="60px">
                    <Stack><Box fontSize="24">[{data?.kind} Request]</Box></Stack>
                </Box>

                    
                    <HStack mb="7"><Box fontSize="20" fontWeight="bold">{data?.srclinicname}</Box><Box>&</Box><Box fontSize="20" fontWeight="bold">{data?.srcliniccode}</Box></HStack>

                    <Box display="flex" justifyContent="space-between" mt={2} fontSize="15" >
                        <Flex>
                            <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Flex mr={5}>
                                        <Box>
                                            <Box>Dr's Nmae:</Box>
                                            <Box>Clinic:</Box>
                                            <Box>Ecount Code:</Box>
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <Box>{data?.srdrname}</Box>
                                            <Box>{data?.srclinicname}</Box>
                                            <Box>{data?.srcliniccode}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                        <Flex>
                            <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Flex mr={5}>
                                        <Box>
                                            <Box>Request Form ID:</Box>
                                            <Box>Sales Rep:</Box>
                                            <Box>Date:</Box>
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <Box>{data?.sr_id}</Box>
                                            <Box>{data?.submitter.name}</Box>
                                            <Box>{data?.sr_submission_date}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
                
                
                {/* Quotation Itmes */}
                <Box display="flex" flexDirection="column" textAlign="center" fontSize="15" mt={20}>
                <Flex mt={5} bg="#385898" color="white" pt="7px" pb="7px">
                    <Box flex="1">ID</Box>
                    <Box flex="6">Item</Box>
                    <Box flex="2">Label</Box>
                    <Box flex="1">QTY</Box>
                </Flex>
                
                {data?.samplerequest_items.map((key, index) => <Flex key={index}>
                {(key.unit_price > 0) ?  
                <>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.item}</Box>
                    <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.serial_number}</Box>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                </> 
                :
                <>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.item}</Box>
                    <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.serial_number}</Box>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                </>  
                }   
                </Flex> 
                )}
                </Box>
                <Box display="flex" justifyContent="space-between" mt={10} fontSize="15" paddingRight="10" paddingLeft="10">
                    <Flex flex="7">
                        <Box>
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <Box color="red" fontSize="10px"><pre><Box>{data?.description}</Box></pre></Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                    <Flex flex={3}></Flex>
                    <Flex flex="0 0 250px" justifyContent="flex-end">
                        <Box fontSize="18px" background="#292929" color="#fff" padding="12px 0 6px 15px" borderRadius="20px">
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box fontWeight="700">Total Price: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">GST: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">Final Price: </Box></HStack>
                                    </Box>
                                </Flex>
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_total_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_gst_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_final_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                {/* Special Condition */}

                {/* Terms & Condition */}
                {/* 
                {data?.quotetncs.map((key, index) => <Box key={index}>
                    <pre><Box mt={20}>{ key.description }</Box></pre>
                </Box> )}
                */}
                </Box>
            )}

            {kind == "Draft Proposal" && (
            <Box 
                pb={20}
                pt={20}
                px={{  
                    base: 10,
                    md: 20,
                    lg: "80px",
                }}
                backgroundColor="#f9f9f9"
                borderRadius="20px"
                >
                <Box display="flex" justifyContent="space-between" mb="20">
                    <HStack><Box><Link to={`/samplerequests/`}><Button colorScheme='facebook'>&#x2190; 샘플 / 데모 / 기안 리스트</Button></Link></Box></HStack>

                    <Box><PDFDownloadLink document={<MyProposalPDF />} fileName={`${data?.submitter.name}_${data?.sr_id}`}>
                    {({loading}) => 
                        loading? (
                        <>Loading Document...</>
                        ) : (
                            <Button colorScheme='facebook'><VscFilePdf /></Button>
                        )
                    }
                    </PDFDownloadLink>
                    </Box>
                </Box>
                
                <Box paddingRight="10" paddingLeft="10">

                <Box textAlign="center" mb="60px">
                    <Stack><Box fontSize="24">[{data?.kind} Request]</Box></Stack>
                </Box>

                    
                    <HStack mb="7"><Box fontSize="20" fontWeight="bold">{data?.srclinicname}</Box><Box>&</Box><Box fontSize="20" fontWeight="bold">{data?.srcliniccode}</Box></HStack>

                    <Box display="flex" justifyContent="space-between" mt={2} fontSize="15" >
                        <Flex>
                            <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Flex mr={5}>
                                        <Box>
                                            <Box>Dr's Nmae:</Box>
                                            <Box>Clinic:</Box>
                                            <Box>Ecount Code:</Box>
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <Box>{data?.srdrname}</Box>
                                            <Box>{data?.srclinicname}</Box>
                                            <Box>{data?.srcliniccode}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                        <Flex>
                            <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Flex mr={5}>
                                        <Box>
                                            <Box>Request Form ID:</Box>
                                            <Box>Sales Rep:</Box>
                                            <Box>Date:</Box>
                                        </Box>
                                    </Flex>
                                    <Flex>
                                        <Box>
                                            <Box>{data?.sr_id}</Box>
                                            <Box>{data?.submitter.name}</Box>
                                            <Box>{data?.sr_submission_date}</Box>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
                
                
                {/* Quotation Itmes */}
                <Box display="flex" flexDirection="column" textAlign="center" fontSize="15" mt={20}>
                <Flex mt={5} bg="#385898" color="white" pt="7px" pb="7px">
                    <Box flex="1">ID</Box>
                    <Box flex="6">Item</Box>
                    <Box flex="2">Label</Box>
                    <Box flex="1">QTY</Box>
                </Flex>
                
                {data?.samplerequest_items.map((key, index) => <Flex key={index}>
                {(key.unit_price > 0) ?  
                <>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.item}</Box>
                    <Box flex="2" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.serial_number}</Box>
                    <Box flex="1" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                </> 
                :
                <>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{index+1}</Box>
                    <Box flex="6" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.item}</Box>
                    <Box flex="2" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.serial_number}</Box>
                    <Box flex="1" color="red" pt="7px" pb="10px" borderBottom="1px solid #D1D3D4" borderRight="1px solid #D1D3D4">{key.qty}</Box>
                </>  
                }   
                </Flex> 
                )}
                </Box>
                <Box display="flex" justifyContent="space-between" mt={10} fontSize="15" paddingRight="10" paddingLeft="10">
                    <Flex flex="7">
                        <Box>
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <Box color="red" fontSize="10px"><pre><Box>{data?.description}</Box></pre></Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                    <Flex flex={3}></Flex>
                    <Flex flex="0 0 250px" justifyContent="flex-end">
                        <Box fontSize="18px" background="#292929" color="#fff" padding="12px 0 6px 15px" borderRadius="20px">
                            <Box display="flex">
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box fontWeight="700">Total Price: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">GST: </Box></HStack>
                                        <HStack mb="10px"><Box fontWeight="700">Final Price: </Box></HStack>
                                    </Box>
                                </Flex>
                                <Flex mr={5}>
                                    <Box>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_total_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_gst_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                        <HStack mb="10px"><Box>{Math.round(data?.sr_final_price *100/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Box></HStack>
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                {/* Special Condition */}

                {/* Terms & Condition */}
                {/* 
                {data?.quotetncs.map((key, index) => <Box key={index}>
                    <pre><Box mt={20}>{ key.description }</Box></pre>
                </Box> )}
                */}
                </Box>
            )}
                
            
            </>
            } else {
                navigate("/")
            }
        } else {
            navigate("/")
        }
        return <Box>{data?.sr_id}</Box>
    }
    return (
        <Box>
        {isLoading ? (
            <>
            <SamplerequestDetailSkeleton />
            </>
        ) : null }
            <Box>
                <CheckUser />
            </Box>
        </Box>
    )
}